import React from "react"
import Layout from "../../components/layout"
import { GatsbySeo } from "gatsby-plugin-next-seo"

const Home = () => {
  return (
    <Layout>
      <GatsbySeo
        title="Eduardo Ceballos - La Gotita Literaria"
        description="Programa radial transmitido por radio nacional en el año 1988"
        canonical="https://eduardoceballos.com/la-gotita-literaria"
        openGraph={{
          url: `https://eduardoceballos.com/la-gotita-literaria`,
          title: `Eduardo Ceballos`,
          description: `Programa radial transmitido por radio nacional en el año 1988`,
          images: [
            {
              url: `https://eduardoceballos.com/seo/index.jpg`,
              alt: `Programa radial transmitido por radio nacional en el año 1988`,
            },
          ],
          site_name: "Eduardo Ceballos - La Gotita Literaria",
        }}
        twitter={{
          handle: "@PoetadeSalta",
          site: "@PoetadeSalta",
          cardType: "summary_large_image",
        }}
      />
      <h1>La Gotita Literaria.</h1>
      <div>
        <p>Amigos les comparto algunos programas radiales denominados La Gotita Literaria que se originaban en Radio Nacional Salta, en 1985, hace 36 años, que se reprogramaban en 52 emisoras argentinas. El trabajo de recuperación lo realizó Ana Soler, responsable encargada de la Mediateca del Archivo Histórico de Salta, a quien le estamos altamente agradecidos.</p>
        <center>
          <audio className="la-gotita-literaria-audio" src="./la-gotita-literaria/la-gotita-literaria.mp3" controls></audio>
        </center>
      </div>
      </Layout>
  )
}

export default Home
